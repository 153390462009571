import React from 'react';
import PropTypes from 'prop-types';
import {Image} from 'semantic-ui-react';
import './styles.css';

export default function CircularImage(props) {

    CircularImage.propTypes = {
        src: PropTypes.string,
        width: PropTypes.number,
        height: PropTypes.string,
        circular: PropTypes.bool,
    };

    CircularImage.defaultProps = {
        src: '',
        width: 120,
        height: "auto",
        circular: true,
    };

    return (
        <Image
            circular={props.circular}
            size="small"
            src={props.src}
            style={{
                width: props.width,
                height: props.height,
            }}
        />
    );
}
