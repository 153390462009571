import React from 'react';
import {Icon, Grid, Card} from 'semantic-ui-react';
import './style.css';
import {isMobile} from 'react-device-detect';

export default function InstructionsCard({hrefForTitle, title, date, duration, description}) {
    return (
        <Card className="instructions-card" centered >
            <Card.Content>
                <Grid>
                    <Grid.Row onClick={hrefForTitle}>
                        <Grid.Column width={10}>
                            <a className="instructions-title">{title}</a>
                        </Grid.Column>
                        <Grid.Column width={6}>
                            <div className="instructions-date">{date} {isMobile ? <br/> : ''} <Icon
                                name='clock outline'/> {duration}</div>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <div className="instructions-card-description">{description}</div>
            </Card.Content>
        </Card>
    );
};