import React, {useEffect, useState} from 'react';
import './styles.css';
import {
    Divider,
    Grid,
    GridColumn,
    GridRow,
    Image,
    Rating,
    Table,
    Statistic
} from 'semantic-ui-react'
import {isMobile} from 'react-device-detect';
import _ from 'lodash';
import {LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer} from 'recharts';


export default function ClientProfile(props) {

    const {userData, navigate} = props;
    const firstName = userData.first_name;
    const lastName = userData.last_name;
    const avatar = userData.profiles.avatar;
    const tasks_last = _.cloneDeep(userData.profiles.tasks.last_tasks);
    const totals_earn = _.cloneDeep(userData.profiles.tasks.totals_earn);
    const ratingAdmin = userData.profiles.rating.admin;
    const ratingCustom = userData.profiles.rating.customer;
    const [totalsEarn, setTotalsEarn] = useState([]);

    useEffect(() => {
        const sortedKeys = _.keys(totals_earn).sort();
        const dataArray = sortedKeys.map(key => {
            return {...totals_earn[key], key};
        });
        setTotalsEarn(dataArray);
    }, []);

    const newTotalsEarn = totalsEarn.map(item => ({
        name: item.key,
        uv: item.total_closed,
    }));

    return (
        <div>
            <a color="blue" className="header-profile">Личный кабинет</a>
            <Grid className="profil-grid">
                <Grid.Row columns={2}>
                    <Grid.Column width={isMobile ? '5' : '4'}>
                        <Grid className="profile-avatar">
                            <Grid.Row columns={1}>
                                <Image
                                    src={avatar}
                                    circular
                                    fluid
                                />
                            </Grid.Row>
                            <Grid.Row className="rating_client_row">
                                <div>от клиентов</div>
                            </Grid.Row>
                            <Grid.Row className="rating_client_row">
                                <div className="rating_client">
                                    <Rating className="rating_client" icon='star' rating={ratingCustom} maxRating={5}
                                            size={!isMobile ? 'large' : 'mini'}
                                            disabled/>
                                </div>
                            </Grid.Row>
                            <Grid.Row className="rating_client_row">
                                <div>от специалиста</div>
                            </Grid.Row>
                            <Grid.Row className="rating_client_row">
                                <Rating className="rating_client" icon='star' rating={ratingAdmin}
                                        maxRating={5} size={!isMobile ? 'large' : 'mini'}
                                        disabled/>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                    <Grid.Column width={isMobile ? '11' : '12'}>
                        <Grid.Row>
                            <div className="firstName-lastName">{firstName + " " + lastName} </div>
                        </Grid.Row>
                        <Divider className="line-another-page"/>
                        <Grid>
                            <GridRow className="another-page" columns={2} onClick={() => navigate("/edit-profile")}>
                                <GridColumn className="name-another-page" width={13}>
                                    <div>Редактировать профиль</div>
                                </GridColumn>
                                <GridColumn className="button-another-page" width={2}>
                                    <a color="blue">⟶</a>
                                </GridColumn>
                            </GridRow>
                            <GridRow className="another-page" columns={2}>
                                <GridColumn className="name-another-page" width={13}>
                                    <div>Оплата услуг</div>
                                </GridColumn>
                                <GridColumn className="button-another-page" width={2}>
                                    <a color="blue">⟶</a>
                                </GridColumn>
                            </GridRow>
                            <GridRow className="another-page" columns={2}>
                                <GridColumn className="name-another-page" width={13}>
                                    <div>Мои отзывы</div>
                                </GridColumn>
                                <GridColumn className="button-another-page" width={2}>
                                    <a color="blue">⟶</a>
                                </GridColumn>
                            </GridRow>
                            <GridRow className="another-page" columns={2}>
                                <GridColumn className="name-another-page" width={13}>
                                    <div>Календарь</div>
                                </GridColumn>
                                <GridColumn className="button-another-page" width={2}>
                                    <a color="blue">⟶</a>
                                </GridColumn>
                            </GridRow>
                        </Grid>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <a color="blue" className="table-of-contents-profile">Последние активные задачи</a>
            <Table celled compact unstackable striped className="table-profile">
                <Table.Body className="body-table-ptofile">
                    {tasks_last.map((task, index) => {
                        return (
                            <Table.Row key={task.id_order}>
                                <Table.Cell color='red' className={index % 2 === 0 ? 'row-table-task-even' : 'row-table-task-odd'}>№{task.id_order}</Table.Cell>
                                <Table.Cell>{task.product_name}</Table.Cell>
                                <Table.Cell textAlign='right'>
                                    {task.customer_name !== " "
                                        ? task.customer_name
                                        : "Организация не указана"
                                    }</Table.Cell>
                            </Table.Row>
                        );
                        return null;
                    })}
                </  Table.Body>
            </Table>
            <a color="blue" className="table-of-contents-profile">Статистика</a>
            <Grid columns={3} className="profile-statistic">
                <Grid.Row>
                    <Grid.Column>
                        <Statistic size="mini">
                            <Statistic.Value
                                className='profile-statistic-value'>
                                {_.last(totalsEarn) !== undefined ? (_.last(totalsEarn).total_in_work) : "0"}
                            </Statistic.Value>
                            <Statistic.Label className="statistic-name">Задач в работе</Statistic.Label>
                        </Statistic>
                    </Grid.Column>
                    <Grid.Column>
                        <Statistic size="mini">
                            <Statistic.Value className='profile-statistic-value'>
                                {
                                    totalsEarn
                                        ? (_.sumBy(totalsEarn, "total_closed"))
                                        : "0"
                                }
                            < /Statistic.Value>
                            <Statistic.Label className="statistic-name">Задач завершено</Statistic.Label>
                        </Statistic>
                    </Grid.Column>
                    <Grid.Column>
                        <Statistic size="mini">
                            <Statistic.Value
                                className='profile-statistic-value'>
                                {
                                    _.last(totalsEarn) !== undefined
                                        ? (_.last(totalsEarn).earned_sum)
                                        : "0"
                                } Р
                            </Statistic.Value>
                            <Statistic.Label className="statistic-name">Поступления в этом месяце</Statistic.Label>
                        </Statistic>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <a color="blue" className="table-of-contents-profile">Задачи за период</a>
            <ResponsiveContainer className="chart-profile">
                <LineChart
                    data={newTotalsEarn}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3"/>
                    <XAxis dataKey="name"/>
                    <YAxis/>
                    <Tooltip/>
                    <Legend/>
                    <Line type="monotone" dataKey="uv" name="Задачи" stroke="#82ca9d"/>
                </LineChart>
            </ResponsiveContainer>
        </div>
    );
}

