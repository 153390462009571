import React, {useState, useRef} from 'react';
import {Grid, Image, ImageGroup, Input} from 'semantic-ui-react'
import PhoneInput from 'react-phone-number-input'
import ru from 'react-phone-number-input/locale/ru'
import {Form, Button} from 'semantic-ui-react'
import 'react-phone-number-input/style.css'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css';
import './style.css';
import {isMobile} from "react-device-detect";
//

export default function EditProfile(props) {

    const {userData, navigate, dispatch} = props;
    const firstName = userData.first_name;
    const lastName = userData.last_name;
    const phone = userData.phone;
    const email = userData.email;
    const speciality = userData.profiles.portfolio.speciality;
    const education = userData.profiles.portfolio.education;
    const avatar = userData.profiles.avatar;
    const [phoneValue, setPhoneValue] = useState(phone);
    const whitelisted_conutries = ['RU', 'AM', 'BY', 'KZ', 'KG'];
    const options = userData.profiles.interests.map(interest => ({
        id: interest.value,
        text: interest.name,
        value: interest.id,
    }));
    const [specValue, setSpecValue] = useState(speciality);
    const changeSpecValue = (value) => {
        setSpecValue(value);
    };
    const [educationValue, setEducationValue] = useState(education);
    const changeEducValue = (value) => {
        setEducationValue(value);
    };
    var toolbarOptions = [
        {'header': [1, 2, 3, 4, 5, 6, false]},
        'bold', 'italic',
        {'list': 'bullet'}, {'list': 'ordered'},
        'blockquote',
        {'align': []},
        'link',
        'clean'
    ];
    const [images, setImages] = useState([]);
    const fileInputRef = useRef(null);

    function selectFiles() {
        fileInputRef.current.click();
    }

    function onFileSelect(event) {
        const files = event.target.files;
        if (files.length === 0) return;
        for (let i = 0; i < files.length; i++) {
            if (files[i].type.split('/')[0] !== 'image') continue;
            if (!images.some((e) => e.name === files[i].name)) {
                setImages((prevImages) => [
                    ...prevImages,
                    {
                        name: files[i].name,
                        url: URL.createObjectURL(files[i]),
                    },
                ])
            }
        }
    }

    function deleteImage(index) {
        setImages((prevImages) =>
            prevImages.filter((_, i) => i !== index));
    }

    return (
        <div className="profile-card-container">
            <div className="edit-profile-header">
                <a className="back-to-profile-btn" onClick={() => navigate("/profile")}>←</a>
                <h3 className="edit-profile-name">Редактировать профиль</h3>
            </div>
            <Grid>
                <Grid.Row columns={2}>
                    <Grid.Column width={isMobile ? '5' : '4'}>
                        <Image
                            src={avatar}
                            circular
                            fluid
                        />
                    </Grid.Column>
                    <Grid.Column width={isMobile ? '11' : '12'}>
                        <Grid>
                            <Grid.Row columns={2}>
                                <Grid.Column>
                                    <p className="headers-for-data">Фамилия</p>
                                    <Input id="lastname" className="profile-input-data" type="text"
                                           defaultValue={lastName}/>
                                </Grid.Column>
                                <Grid.Column>
                                    <p className="headers-for-data">Имя</p>
                                    <Input id="name" className="profile-input-data" type="text"
                                           defaultValue={firstName}/>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns={2}>
                                <Grid.Column>
                                    <p className="headers-for-data">Телефон</p>
                                    <PhoneInput
                                        className="phone-input"
                                        countries={whitelisted_conutries}
                                        labels={ru}
                                        displayInitialValueAsLocalNumber
                                        value={phoneValue}
                                        onChange={setPhoneValue}
                                    />
                                </Grid.Column>
                                <Grid.Column>
                                    <p className="headers-for-data">Почта</p>
                                    <Input id="email" className="profile-input-data" type="text" defaultValue={email}/>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <div className="personal-interests">
                <p className="profile-edit-block-headers">Личные интересы</p>
                <div className="interests-content">
                    <Form>
                        <Form.Dropdown label="Выберите Ваши интересы из списка" fluid multiple selection
                                       options={options}
                                       defaultValue={options.filter(option => option.id === 1).map(option => option.value)}/>
                    </Form>
                </div>
            </div>
            <div className="specialization-content">
                <p className="profile-edit-block-headers">Специализация</p>
                <ReactQuill modules={{toolbar: toolbarOptions}} theme="snow"
                            className="spec-plchld-style"
                            placeholder="Пожалуйста, расскажите о своей специализации"
                            value={specValue}
                            onChange={changeSpecValue}
                            onFocus={() => {
                                const toolbar = document.querySelector('.specialization-content .quill .ql-toolbar');
                                toolbar.style.display = 'block';
                            }}
                            onBlur={() => {
                                if (!document.querySelector('.ql-header.ql-picker.ql-expanded') && !document.querySelector('.ql-align.ql-picker.ql-icon-picker.ql-expanded')) {
                                    const toolbar = document.querySelector('.specialization-content .quill .ql-toolbar');
                                    toolbar.style.display = 'none';
                                }
                            }}
                />
            </div>
            <div className="education-content">
                <p className="profile-edit-block-headers">Образование и достижения</p>
                <ReactQuill modules={{toolbar: toolbarOptions}} theme="snow"
                            value={educationValue}
                            placeholder="Пожалуйста, расскажите о своем образовании и достижениях"
                            onChange={changeEducValue}
                            onFocus={() => {
                                const toolbar = document.querySelector('.education-content .quill .ql-toolbar');
                                toolbar.style.display = 'block';
                            }}
                            onBlur={() => {
                                if (!document.querySelector('.ql-header.ql-picker.ql-expanded') && !document.querySelector('.ql-align.ql-picker.ql-icon-picker.ql-expanded')) {
                                    const toolbar = document.querySelector('.education-content .quill .ql-toolbar');
                                    toolbar.style.display = 'none';
                                }
                            }}
                />
            </div>
            <div className="profile-images">
                <p className="profile-edit-block-headers">Загрузите фотографии в Вашу галерею</p>
                <div className="profile-gallery">
                    <Button primary onClick={selectFiles}>Загрузить</Button>
                    <input name="file" type="file" className="file"
                           accept="image/*" multiple ref={fileInputRef}
                           onChange={onFileSelect}
                           style={{display: 'none'}}
                    />
                    {images.map((images, index) => (
                        <div className="container-profile-image" key={index}>
                            <span className="remove-profile-image" onClick={() => deleteImage(index)}>&times;</span>
                            <ImageGroup>
                                <Image src={images.url}></Image>
                            </ImageGroup>
                        </div>
                    ))}
                </div>
            </div>
            <Grid>
                <Grid.Row columns={2}>
                    <Grid.Column>
                        <Button negative className="button-edit-profile"
                                         onClick={() => navigate("/profile")}>Отменить</Button>
                    </Grid.Column>
                    <Grid.Column>
                        <Button positive className="button-edit-profile">Сохранить</Button>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </div>
    )
}