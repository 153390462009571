import React from 'react';
import './style.css';
import ReactPlayer from 'react-player'
import {Header, Modal} from 'semantic-ui-react';

export default function ModalPlayer(props){
    const {open, handleOpen, handleClose, url, headerText} = props;

    return (
        <Modal
            closeIcon
            dimmer
            centered
            open={open}
            onOpen={handleOpen}
            onClose={handleClose}
            className="modal-player"
        >
            <Header content={headerText}/>
            <Modal.Content className="player-content">
                    <ReactPlayer
                        config={{file: {attributes: {controlsList: 'nodownload'}}}}
                        onContextMenu={e => e.preventDefault()}
                        className="react-player"
                        url={url}
                        controls={true}
                        playing={true}
                        width='100%'
                        height='100%'
                    />
            </Modal.Content>
        </Modal>
    )
}