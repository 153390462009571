import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {
    Card, Divider, Header, Icon, Loader, Button,
    Segment, Modal, Dimmer, Image, Grid,
} from 'semantic-ui-react';

import {getPlaceOrderInProgress} from "../../views/Orders/reducer";
import {Link} from "react-router-dom";

import './styles.css';
import {getAddressFields, getCountries, isCountriesFetching} from "../AddressCard/reducer";
import {
    ADDRESS_ADDINFO,
    ADDRESS_ADDRESS,
    ADDRESS_CITY,
    ADDRESS_COMPANY,
    ADDRESS_COMPANY_ADDR,
    ADDRESS_COMPANY_JOB_TITLE,
    ADDRESS_COMPANY_SCOPE,
    ADDRESS_COUNTRY,
    ADDRESS_EMAIL,
    ADDRESS_FIRSTNAME, ADDRESS_GEOLAT, ADDRESS_GEOLON,
    ADDRESS_LASTNAME,
    ADDRESS_PHONE, ADDRESS_PHONE_COUNTRY,
    ADDRESS_POSTCODE,
    ADDRESS_STATE,
    fetchCounties,
    setAddressFields
} from "../AddressCard/actions";
import {getAuthToken, getUserData} from "../UserLogin/reducer";
import OrderCardHeader from "./OrderCardHeader";
import AddressCard from "../AddressCard";
import {receiveOrderData, updateOrder} from "../../views/Orders/actions";
import {useReactToPrint} from "react-to-print";
import ModalPayment from "../ModalPayment/ModalPayment";

export default function MiniOrderCard(props) {

    const { order, circular, header, actionButtons, dispatch, token, userData, userLocation, mixpanel } = props;
    const { billing, id } = order;

    const getLoading = useSelector((state) => getPlaceOrderInProgress(state.orders));
    const getLoadingCountries = useSelector((state) => isCountriesFetching(state.address));
    const countries = useSelector((state) => getCountries(state.address));
    const fields = useSelector((state) => getAddressFields(state.address));

    const [openAddressModal, setOpenAddressModal] = useState(false);
    const [paymentOpen, setPaymentOpen] = React.useState(false);

    const country = typeof countries === 'object' && typeof countries.countries === 'object'
        ? countries.countries[billing.country] ?? {} : {};
    const countryStates = typeof country === 'object' && country.states
        ? country.states.filter( item => String(item.code) === billing.state ) : [];

    useEffect(() => {
        if (typeof country.iso === 'undefined' && !getLoadingCountries) {
            console.warn('Mini order card has queried countries ...');
            dispatch(fetchCounties());
        }
        dispatch(receiveOrderData([]));
    }, []);

    const orderCountry = country.iso ? country.emoji + ' ' + country.country_ru : billing.country;
    const orderState = countryStates.length ? countryStates[0].name : `регион ${billing.state}`;
    const orderAddress = billing.city === billing.address_1 ? '' : billing.address_1;

    const needsPayment = order.status === 'pending' || order.status === 'on-hold';

    const OrderImage = () => (
        <Image src={order.file_image ?? "https://react.semantic-ui.com/images/wireframe/square-image.png"}
               size="medium"
               circular={ order.file_image ? false : circular}
               className="order-image"
        />
    );

    function timeout(delay) {
        return new Promise( res => setTimeout(res, delay) );
    }

    function openModalAddress() {

        const orderFields = {
            [ADDRESS_FIRSTNAME]: billing.first_name,
            [ADDRESS_LASTNAME]: billing. last_name,
            [ADDRESS_COMPANY]: billing.company ?? '',
            [ADDRESS_COMPANY_JOB_TITLE]: billing.company_job_title ?? '',
            [ADDRESS_COMPANY_SCOPE]: billing.company_scope ?? '',
            [ADDRESS_ADDRESS]: billing.address_1 ?? '',
            [ADDRESS_ADDINFO]: billing.address_2 ?? '',
            [ADDRESS_COUNTRY]: billing.country,
            [ADDRESS_STATE]: billing.state,
            [ADDRESS_CITY]: billing.city,
            [ADDRESS_POSTCODE]: billing.postcode,
            [ADDRESS_PHONE]: billing.phone,
            [ADDRESS_EMAIL]: billing.email ?? '',
        };

        dispatch(setAddressFields(orderFields));
        setOpenAddressModal(true);

        if (mixpanel)
            mixpanel.track('OrderCard Edit Address Open', { ...userLocation, ...userData, id });
    }
    function changeOrderAddress() {
        const savedFields = {
            user_location: {
                userData: {id: userData.id, login: userData.login},
                userLocation,
            },

            company_meta: fields[ADDRESS_COMPANY_ADDR] ?? '',

            billing : {
                first_name: fields[ADDRESS_FIRSTNAME],
                last_name: fields[ADDRESS_LASTNAME],
                company: fields[ADDRESS_COMPANY] ?? '',
                company_job_title: fields[ADDRESS_COMPANY_JOB_TITLE] ?? '',
                company_scope: fields[ADDRESS_COMPANY_SCOPE] ?? '',
                address_1: fields[ADDRESS_ADDRESS] ?? '',
                address_geo_lon: fields[ADDRESS_GEOLON] ?? '',
                address_geo_lat: fields[ADDRESS_GEOLAT] ?? '',
                address_2: fields[ADDRESS_ADDINFO] ?? '',
                country: fields[ADDRESS_COUNTRY],
                state: fields[ADDRESS_STATE],
                city: fields[ADDRESS_CITY],
                postcode: fields[ADDRESS_POSTCODE],
                phone: fields[ADDRESS_PHONE],
                phone_country: fields[ADDRESS_PHONE_COUNTRY],
                email: fields[ADDRESS_EMAIL] ?? '',
            },
        };

        const query = JSON.stringify({billing: savedFields});
        dispatch(updateOrder(token, id, query, order.order_key));

        setOpenAddressModal(false);

        if (mixpanel)
            mixpanel.track('OrderCard Edit Address Save', { ...userLocation, ...userData, id });
    }

    let componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const modalAddress = () => (
        <Modal
            className="modal-order-edit-address"
            closeIcon
            dimmer
            open={openAddressModal}
            onClose={()=>setOpenAddressModal(false)}
            onOpen={()=>setOpenAddressModal(true)}
            centered
            size="fullscreen"
            closeOnDimmerClick={true}
        >
            <Header icon="address card" content={`Изменение заказа №${id}`} />
            <Modal.Content>
                <AddressCard {...props}/>
            </Modal.Content>
            <Modal.Actions>
                <Button color='red' onClick={()=>setOpenAddressModal(false)}>
                    <Icon name='remove' /> Отмена
                </Button>
                <Button color='green' onClick={changeOrderAddress} disabled={fields.hasErrors}>
                    <Icon name='checkmark' /> Сохранить
                </Button>
            </Modal.Actions>
        </Modal>
    );

    return (
        <div>
            <Card centered color="blue" raised>
                { header ?
                    <div>
                        <OrderCardHeader order={order} actionButtons={false} {...props}/>
                        <Dimmer active={ [1, id].includes(getLoading) || getLoadingCountries } inverted>
                            <Loader inverted />
                        </Dimmer>
                    </div>
                 : ''}
                <Card.Content>
                    <Grid>
                        <Grid.Column className="order-mini-card-header-first-col">
                            <OrderImage />
                        </Grid.Column>
                        <Grid.Column className="order-mini-card-header-second-col">
                            <Link to={'/orders/' + id} >
                                <Card.Header as="h4" className="order-header-fio-col">
                                    { billing.last_name + ' ' + billing.first_name }<br/>
                                    { billing.company }<br/>
                                    <br/>
                                    { billing.company_job_title }<br/>
                                    { billing.company_scope }<br/>
                                </Card.Header>
                            </Link>
                        </Grid.Column>
                    </Grid>
                    <Divider />
                    <div className="order-mini-card-country" size="small" dangerouslySetInnerHTML={{ __html: `${orderCountry}, ${orderState}` }} />
                    <div  className="order-mini-card-city" size="small" dangerouslySetInnerHTML={{ __html: `${billing.postcode} ${billing.city}` }} />
                    <div  className="order-mini-card-address" size="small" dangerouslySetInnerHTML={{ __html: `${orderAddress} ${billing.address_2}` }} />

                    <Segment color="blue" className="order-mini-card-contacts">
                        <a className="order-mini-card-phone" href={'tel:' + billing.phone}>
                            <Icon name="call" size="large" />
                            {billing.phone}
                        </a>
                        <a className="order-mini-card-mail" href={'mailto:' + billing.email}>
                            <Icon name="mail" size="large" />
                            {billing.email}
                        </a>
                    </Segment>

                </Card.Content>
                <Card.Content extra>
                    <div className="order-card-action-buttons">
                        { actionButtons
                            ?
                            <div>
                                <Button.Group className="mini-order-action-buttons" fluid>
                                    <Button
                                        color="grey"
                                        onClick={openModalAddress}
                                        disabled={order.status === 'cancelled'}
                                        compact
                                    >
                                        <Icon name='pencil' />
                                        Изменить
                                    </Button>
                                    { needsPayment ?
                                        <Button
                                            color="green"
                                            onClick={() => setPaymentOpen(true)}
                                            compact
                                        >
                                            <Icon name='payment' />
                                            Оплатить
                                        </Button>
                                    : null}
                                </Button.Group>
                                { modalAddress() }
                                <ModalPayment
                                    open = {paymentOpen}
                                    headerIcon = "payment"
                                    headerText = "Оплата заказа"
                                    handleOpen = {() => setPaymentOpen(true)}
                                    handleClose = {() => setPaymentOpen(false)}
                                    order = {order}
                                />
                            </div>
                            : <Link to={'/orders/' + id} >
                                    <Button fluid color="orange">
                                        Подробнее
                                    </Button>
                            </Link> }
                    </div>
                </Card.Content>
            </Card>

        </div>
        );
}